import { TRANSLATION_CUSTOM_EVENTS } from '../src/appConstants';
import * as translations from './combinedTranslations.json';

/**
 * This fires custom event TRANSLATIONS_CONTENT to pass the translation content
 */
const triggerTranslationsEvent = () => {
  const transEvent = new CustomEvent(TRANSLATION_CUSTOM_EVENTS.TRANSLATIONS_CONTENT, { detail: translations });
  document.dispatchEvent(transEvent);
};

/**
 * This listens to custom event SEND_TRANSLATION_CONTENT which is fired by I18n Component when it mounts.
 * When this event is fired, translation content is again shared via TRANSLATIONS_CONTENT event.
 */
document.addEventListener(TRANSLATION_CUSTOM_EVENTS.SEND_TRANSLATION_CONTENT, () => {
  triggerTranslationsEvent();
});

/**
 * Fire TRANSLATIONS_CONTENT event once translation bundle is loaded.
 */
triggerTranslationsEvent();
